function hoursToSeconds(hours) {
  let calcHours = parseInt(hours.split(':')[0]);
  let calcMinutes = parseInt(hours.split(':')[1]);
  return calcHours * 3600 + calcMinutes * 60;
}

module.exports = {
  secondsToHours(seconds) {
    let minuut;
    const hh = Math.floor(seconds / 3600);
    seconds -= hh * 3600;
    const mm = Math.floor(seconds / 60);
    if (mm < 10) {
      minuut = `0${mm.toString()}`;
    } else {
      minuut = mm.toString();
    }
    return `${hh.toString()}:${minuut}`;
  },
  calcDuration(start, landing) {
    const startUur = parseInt(start.substring(0, 2));
    const startMinuut = parseInt(start.substring(3, 5));
    const landingUur = parseInt(landing.substring(0, 2));
    const landingMinuut = parseInt(landing.substring(3, 5));
    let startTijd = new Date(2021, 0, 1, startUur, startMinuut);
    let landingTijd = new Date(2021, 0, 1, landingUur, landingMinuut);

    let seconds = Math.abs(landingTijd - startTijd) / 1000;

    let minuut, uur;
    const hh = Math.floor(seconds / 3600);
    seconds -= hh * 3600;
    if (hh < 10) {
      uur = `0${hh.toString()}`;
    } else {
      uur = hh.toString();
    }
    const mm = Math.floor(seconds / 60);
    if (mm < 10) {
      minuut = `0${mm.toString()}`;
    } else {
      minuut = mm.toString();
    }
    return `${uur}:${minuut}`;
  },
  calcHoursDiff(newHours, oldHours) {
    const newSeconds = hoursToSeconds(newHours);
    const oldSeconds = hoursToSeconds(oldHours);
    return newSeconds - oldSeconds;
  },
};
