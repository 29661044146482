<template>
  <div>
    <v-container class="d-flex flex-col mb-6">
      <v-data-table
        dense
        :headers="headers"
        :items="totals"
        :footer-props="{ 'items-per-page-options': [10, 15, 20, 25, 30] }"
        :items-per-page="15"
        class="elevation-1"
      ></v-data-table>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      headers: [
        { text: 'Veld', class: 'blue--text text--darken-4', value: 'veld' },
        { text: 'Totaal', align: 'right', sortable: false, class: 'blue--text text--darken-4', value: 'totaal' },
        { text: 'Lier', align: 'right', sortable: false, class: 'blue--text text--darken-4', value: 'lier' },
        { text: 'Sleep', align: 'right', sortable: false, class: 'blue--text text--darken-4', value: 'sleep' },
        { text: 'TMG', align: 'right', sortable: false, class: 'blue--text text--darken-4', value: 'tmg' },
        {
          text: 'Duur',
          align: 'right',
          sortable: false,
          class: 'rechts text-right blue--text text--darken-4',
          value: 'duur',
        },
      ],
      totals: [],
    };
  },
  async mounted() {
    await this.$store.dispatch('totalsField');
    this.totals = this.$store.getters.totalsField;
  },
};
</script>
