export default {
  //////AUTH//////
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_PILOT(state, pilot) {
    state.pilot = pilot;
  },
  SET_AUTH(state, payload) {
    state.authenticated = payload;
  },
  //////REPORT//////
  SET_TOTALS(state, totals) {
    state.totals = totals;
  },
  SET_TWENTYFLIGHTS(state, flights) {
    state.twentyFlights = flights;
  },
  SET_ALLFLIGHTS(state, flights) {
    state.allFlights = flights;
  },
  SET_TOTALSYEAR(state, totals) {
    state.totalsYear = totals;
  },
  SET_TOTALSYEARINSTRUCTION(state, totals) {
    state.totalsYearInstruction = totals;
  },
  SET_TOTALSTYPE(state, totals) {
    state.totalsType = totals;
  },
  SET_TOTALSTYPEYEAR(state, totals) {
    state.totalsTypeYear = totals;
  },
  SET_TOTALSFIELD(state, totals) {
    state.totalsField = totals;
  },
  SET_TOTALSFIELDYEAR(state, totals) {
    state.totalsFieldYear = totals;
  },
  SET_TOTALSINSTRUCTOR(state, totals) {
    state.totalsInstructor = totals;
  },
  SET_TOTALSMETHOD(state, totals) {
    state.totalsMethod = totals;
  },
  SET_PAGETOTALS(state, totals) {
    state.pageTotals = totals;
  },
  SET_TOTALSPLANE(state, totals) {
    state.totalsPlane = totals;
  },
  //////MAINT//////
  SET_EDITFLIGHT(state, flight) {
    state.editFlight = flight;
  },
  SET_NEWFLIGHTNO(state, flightNo) {
    state.newFlightNo = flightNo;
  },
  SET_PILOTFIELDS(state, fields) {
    state.pilotFields = fields;
  },
  SET_PILOTPLANES(state, planes) {
    state.pilotPlanes = planes;
  },
  SET_PILOTTYPES(state, types) {
    state.pilotTypes = types;
  },
  SET_PILOTINSTRUCTORS(state, instructors) {
    state.pilotInstructors = instructors;
  },
  SET_TYPEREGISTRATIONS(state, registrations) {
    state.typeRegistrations = registrations;
  },
  SET_PILOTPLANE(state, plane) {
    state.pilotPlane = plane;
  },
  SET_CROSSCOUNTRY(state, flights) {
    state.crossCountry = flights;
  },
};
