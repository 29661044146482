export default {
  ///////AUTH///////
  pilot(state) {
    return state.pilot;
  },
  authenticated(state) {
    return state.authenticated;
  },
  //////REPORT//////
  totals(state) {
    return state.totals;
  },
  token(state) {
    return state.token;
  },
  twentyFlights(state) {
    return state.twentyFlights;
  },
  allFlights(state) {
    return state.allFlights;
  },
  totalsYear(state) {
    return state.totalsYear;
  },
  totalsYearInstruction(state) {
    return state.totalsYearInstruction;
  },
  totalsType(state) {
    return state.totalsType;
  },
  totalsTypeYear(state) {
    return state.totalsTypeYear;
  },
  totalsField(state) {
    return state.totalsField;
  },
  totalsFieldYear(state) {
    return state.totalsFieldYear;
  },
  totalsInstructor(state) {
    return state.totalsInstructor;
  },
  totalsMethod(state) {
    return state.totalsMethod;
  },
  pageTotals(state) {
    return state.pageTotals;
  },
  totalsPlane(state) {
    return state.totalsPlane;
  },
  //////MAINT//////
  editFlight(state) {
    return state.editFlight;
  },
  newFlightNo(state) {
    return state.newFlightNo;
  },
  pilotFields(state) {
    return state.pilotFields;
  },
  pilotPlanes(state) {
    return state.pilotPlanes;
  },
  pilotTypes(state) {
    return state.pilotTypes;
  },
  pilotInstructors(state) {
    return state.pilotInstructors;
  },
  typeRegistrations(state) {
    return state.typeRegistrations;
  },
  pilotPlane(state) {
    return state.pilotPlane;
  },
  crossCountry(state) {
    return state.crossCountry;
  },
};
