import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0D47A1',
        secondary: '#e3f2fd',
        success: '#00897B',
        accent: '#8c9eff',
        error: '#EF9A9A',
        warning: '#E57373',
      },
    },
  },
});
