<template>
  <div>
    <v-container class="d-flex flex-col mb-6">
      <v-data-table dense :headers="headers" :items="totals" :items-per-page="15" class="elevation-1"></v-data-table>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      headers: [
        { text: 'Lier', align: 'right', sortable: false, class: 'blue--text text--darken-4', value: 'lier' },
        { text: 'Sleep', align: 'right', sortable: false, class: 'blue--text text--darken-4', value: 'sleep' },
        { text: 'TMG', align: 'right', sortable: false, class: 'blue--text text--darken-4', value: 'tmg' },
        {
          text: 'Duur',
          align: 'right',
          sortable: false,
          class: 'rechts text-right blue--text text--darken-4',
          value: 'duur',
        },
      ],
      totals: [],
    };
  },
  async mounted() {
    await this.$store.dispatch('totalsMethod');
    this.totals = this.$store.getters.totalsMethod;
  },
};
</script>
