import Vue from 'vue';
import Vuex from 'vuex';

import getters from './getters';
import mutations from './mutations';
import actions from './actions';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    ////AUTH////
    token: null,
    pilot: {},
    authenticated: false,
    ////REPORT////
    totals: null,
    twentyFlights: null,
    allFlights: null,
    totalsYear: [],
    totalsYearInstruction: [],
    totalsType: [],
    totalsTypeYear: [],
    totalsField: [],
    totalsFieldYear: [],
    totalsInstructor: [],
    totalsMethod: [],
    totalsPlane: [],
    ////MAINT////
    editFlight: null,
    pilotFields: [],
    pilotInstructors: [],
    pilotPlanes: [],
    pilotPlane: [],
    pilotTypes: [],
    typeRegistrations: [],
    newFlightNo: 0,
    pageTotals: {},
    crossCountry: [],
  },
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: {},
});
