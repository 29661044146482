<template>
  <v-app style="background: #e3f2fd">
    <nav>
      <v-toolbar dense class="primary">
        <router-link to="/home" v-if="this.authenticated">
          <v-toolbar-title dark class="white--text text-uppercase">
            <span class="font-weight-light">Pilot </span>
            <span>Logbook</span>
          </v-toolbar-title>
        </router-link>
        <router-link to="/" v-else>
          <v-toolbar-title class="white--text text-uppercase">
            <span class="font-weight-light">Pilot </span>
            <span>Logbook</span>
          </v-toolbar-title>
        </router-link>
        <v-img src="@/img/ls3.png" max-height="30" max-width="30" class="ml-5 white--text "></v-img>
        <v-toolbar-title class="subtitle-1 white--text pl-10" v-if="this.authenticated">
          <strong>{{ this.pilot.first_name }}</strong> -- <strong>Starts:</strong> {{ this.totals.starts }} -
          <strong>Uren:</strong>
          {{ this.totals.uren }}
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-toolbar-items class="d-none d-sm-flex" v-if="this.authenticated">
          <v-btn text class="white--text text--darken-4" to="/report/pagetotals">
            <v-icon class="white--text mr-3">mdi-calculator</v-icon>
          </v-btn>
          <v-btn text class="white--text text--darken-4" to="/report/wrapper">
            <v-icon class="white--text mr-3">mdi-file-document-multiple-outline</v-icon>
          </v-btn>
          <v-btn text class="white--text text--darken-4" @click="logOut">
            <v-icon class="white--text text--darken-4 mr-3">mdi-logout</v-icon>
          </v-btn>
        </v-toolbar-items>
        <v-toolbar-items class="d-none d-sm-flex" v-else>
          <v-btn text class="white--text text--darken-4" to="/pilot/signin">
            <v-icon class="white--text text--darken-4 mr-3">mdi-login</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </nav>
    <main>
      <router-view></router-view>
    </main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'App',

  components: {},

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      pilot: 'pilot',
      authenticated: 'authenticated',
      totals: 'totals',
    }),
  },

  methods: {
    ...mapActions({
      signOut: 'signOut',
    }),
    logOut() {
      this.signOut().then(() => {
        this.$router.replace({ name: 'blank' });
      });
    },
  },
};
</script>
