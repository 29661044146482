<template>
  <div>
    <v-container>
      <v-col md="4" class="mt-16 ">
        <v-row>
          <v-text-field
            v-model="startnoPrevious"
            label="Startnummer vorige pagina"
            @click="isCalculated = false"
          ></v-text-field>
        </v-row>
        <v-row>
          <v-text-field v-model="startnoActual" label="Startnummer deze pagina"></v-text-field>
        </v-row>
        <v-row>
          <v-btn @click="calculate" color="primary">Bereken</v-btn>
        </v-row>
      </v-col>
      <v-col md="10" class="mt-10">
        <v-simple-table v-if="isCalculated" class="elevation-1">
          <thead>
            <tr>
              <th class="text-left"></th>
              <th class="text-right">
                Vluchtduur
              </th>
              <th class="text-right">
                Landingen
              </th>
              <th class="text-right">
                Gezagvoerder
              </th>
              <th class="text-right">
                DBO
              </th>
              <th class="text-right">
                Instructie
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Totaal deze pagina</td>
              <td class="text-right">{{ actualPage.flight_time }}</td>
              <td class="text-right">{{ actualPage.landings }}</td>
              <td class="text-right">{{ actualPage.PIC }}</td>
              <td class="text-right">{{ actualPage.DBO }}</td>
              <td class="text-right">{{ actualPage.instructor }}</td>
            </tr>
            <tr>
              <td>Vorig totaal</td>
              <td class="text-right">{{ previousPage.flight_time }}</td>
              <td class="text-right">{{ previousPage.landings }}</td>
              <td class="text-right">{{ previousPage.PIC }}</td>
              <td class="text-right">{{ previousPage.DBO }}</td>
              <td class="text-right">{{ previousPage.instructor }}</td>
            </tr>
            <tr>
              <td>Totaal</td>
              <td class="text-right">{{ newTotals.flight_time }}</td>
              <td class="text-right">{{ newTotals.landings }}</td>
              <td class="text-right">{{ newTotals.PIC }}</td>
              <td class="text-right">{{ newTotals.DBO }}</td>
              <td class="text-right">{{ newTotals.instructor }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import { calcHoursDiff, secondsToHours } from '../../util/standard';
export default {
  components: {},
  data() {
    return {
      startnoPrevious: '',
      startnoActual: '',
      previousPage: {},
      actualPage: {},
      newTotals: {},
      isCalculated: false,
    };
  },
  async mounted() {},
  methods: {
    async calculate() {
      await this.$store.dispatch('pageTotals', this.startnoPrevious);
      this.previousPage = this.$store.getters.pageTotals;
      await this.$store.dispatch('pageTotals', this.startnoActual);
      this.newTotals = this.$store.getters.pageTotals;
      // Bereken paginatotalen
      this.actualPage.flight_time = secondsToHours(
        calcHoursDiff(this.newTotals.flight_time, this.previousPage.flight_time)
      );
      this.actualPage.landings = this.newTotals.landings - this.previousPage.landings;
      this.actualPage.PIC = secondsToHours(calcHoursDiff(this.newTotals.PIC, this.previousPage.PIC));
      this.actualPage.DBO = secondsToHours(calcHoursDiff(this.newTotals.DBO, this.previousPage.DBO));
      this.actualPage.instructor = secondsToHours(
        calcHoursDiff(this.newTotals.instructor, this.previousPage.instructor)
      );
      this.isCalculated = true;
    },
  },
};
</script>
