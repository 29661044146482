<template>
  <div>
    <v-container>
      <v-row class="mt-1 mb-2">
        <h3 class="text-left blue--text text--darken-4 ml-4">Laatste tien starts</h3>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="white--text mr-4" small @click="addFlight">+</v-btn>
      </v-row>
      <v-simple-table dense class="elevation-1">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left blue--text text--darken-4">
                Vluchtno
              </th>
              <th class="text-left blue--text text--darken-4">
                Datum
              </th>
              <th class="text-left blue--text text--darken-4">
                Veld
              </th>
              <th class="text-left blue--text text--darken-4">
                Start
              </th>
              <th class="text-left blue--text text--darken-4">
                Type
              </th>
              <th class="text-left blue--text text--darken-4">
                Registratie
              </th>
              <!-- <th class="text-left blue--text text--darken-4">
                Methode
              </th> -->
              <th class="text-left blue--text text--darken-4">
                Duur
              </th>
              <th class="text-left blue--text text--darken-4">
                Opmerking
              </th>
              <th class="text-left blue--text text--darken-4"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="start in twentyFlights" :key="start.ID">
              <td>{{ start.flight_no }}</td>
              <td>{{ start.date }}</td>
              <td>{{ start.name_field_start }}</td>
              <td>{{ start.time_start }}</td>
              <td>{{ start.name_type }}</td>
              <td>{{ start.registration }}</td>
              <!-- <td>{{ start.launch_method }}</td> -->
              <td>{{ start.duration }}</td>
              <td>{{ start.remark }}</td>
              <td><v-icon dense @click="editFlight(start.flight_no, start.ID)" small>mdi-pencil</v-icon></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-dialog v-model="dialog" persistent>
        <!-- ************************************************************************************************************** -->
        <v-card>
          <p
            v-if="this.dialogMode === 'edit'"
            dense
            class="white--text pt-1 pb-1 pl-10"
            style="background-color:#0D47A1; font-size:18px"
          >
            Edit start {{ editedFlight.flight_no }}
          </p>
          <p v-else dense class="white--text pt-1 pb-1 pl-10" style="background-color:#0D47A1; font-size:18px">
            Toevoegen start {{ editedFlight.flight_no }}
          </p>
          <v-container>
            <!-- form begint HIER -->
            <v-form ref="form" v-model="formValid" lazy-validation>
              <v-row>
                <v-col md="12">
                  <v-row>
                    <v-col md="4">
                      <v-menu ref="menu" v-model="menu" :close-on-content-click="true" offset-y min-width="200px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedFlight.date"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker dense v-model="editedFlight.date" no-title scrollable> </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col md="4">
                      <v-select
                        :items="pilotTypesValues"
                        v-model="nameType"
                        prepend-icon="mdi-airplane"
                        dense
                        @change="typeChanged"
                      ></v-select>
                    </v-col>
                    <v-col md="4">
                      <v-row class="pt-2">
                        <v-radio-group row dense v-model="editedFlight.flight_type" @change="flightTypeChanged">
                          <v-radio value="S" label="Solo"></v-radio>
                          <v-radio value="P" label="Pax" v-if="this.double"></v-radio>
                          <v-radio value="D" label="DBO" v-if="this.double"></v-radio>
                          <v-radio value="I" label="Instructie" v-if="this.double && this.pilot.instructor"></v-radio>
                        </v-radio-group>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="4">
                      <v-select
                        :items="pilotFieldsValues"
                        v-model="nameFieldStart"
                        prepend-icon="mdi-airplane-takeoff"
                        dense
                      ></v-select>
                    </v-col>
                    <v-col md="4">
                      <v-select
                        :items="typeRegistrationsValues"
                        v-model="registration"
                        prepend-icon="mdi-numeric"
                        dense
                      ></v-select>
                    </v-col>
                    <v-col md="4" class="mt-3">
                      <v-row>
                        <v-select
                          v-if="showInstructors"
                          :items="pilotInstructorsValues"
                          v-model="instructorName"
                          prepend-icon="mdi-teach"
                          dense
                        ></v-select>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="4">
                      <v-menu
                        ref="menu"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedFlight.time_start"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu2"
                          v-model="editedFlight.time_start"
                          format="24hr"
                          @click:minute="mmsChanged"
                          @click:hour="hhChanged"
                          width="290"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col md="4">
                      <v-row>
                        <v-radio-group dense v-model="editedFlight.launch_method">
                          <v-row class="mt-6  ml-11">
                            <v-radio value="L" label="Lier" class="mr-2"></v-radio>
                            <v-radio value="S" label="Sleep" class="mr-2"></v-radio>
                            <!-- <v-radio value="T" label="TMG"></v-radio> -->
                            <p></p>
                          </v-row>
                        </v-radio-group>
                      </v-row>
                    </v-col>
                    <v-col md="4">
                      <v-row>
                        <v-checkbox v-model="editedFlight.cross_country" label="Overland" class="ml-2"></v-checkbox>
                        <v-checkbox v-model="editedFlight.outlanding" label="Buiten" class="ml-2"></v-checkbox>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="4">
                      <v-select
                        :items="pilotFieldsValues"
                        v-model="nameFieldLanding"
                        prepend-icon="mdi-airplane-landing"
                        dense
                      ></v-select>
                    </v-col>
                    <v-col md="4">
                      <v-text-field dense readonly prepend-icon="mdi-timer-outline" v-model="duration"></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        dense
                        prepend-icon="mdi-map-marker-distance"
                        v-model="editedFlight.distance"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="4">
                      <v-menu
                        ref="menu"
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedFlight.time_landing"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu3"
                          v-model="editedFlight.time_landing"
                          format="24hr"
                          @click:minute="mmlChanged"
                          @click:hour="hhChanged"
                          width="290"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        v-model="editedFlight.no_of_flights"
                        prepend-icon="mdi-pound"
                        v-if="this.pilot.instructor"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4"></v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-text-field dense prepend-icon="mdi-account-voice" v-model="editedFlight.remark"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-text-field dense prepend-icon="mdi-map-outline" v-model="editedFlight.olc"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <!-- form eindigt HIER -->
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small color="warning" @click="dialog = false">
                Cancel
              </v-btn>
              <v-btn small color="primary" @click="processInput">
                Opslaan
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
        <!-- ************************************************************************************************************** -->
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { calcDuration } from '../util/standard';
export default {
  data() {
    return {
      pilot: {},
      twentyFlights: [],
      dialog: false,
      editedFlight: {},
      formValid: false,
      ////EDIT/ADD START////
      dialogMode: '',
      menu: false,
      menu2: false,
      menu3: false,
      pilotFields: [],
      pilotFieldsValues: [],
      nameFieldStart: '',
      nameFieldLanding: '',
      pilotPlane: {},
      pilotPlanes: [],
      pilotPlanesValues: [],
      typeRegistrations: [],
      typeRegistrationsValues: [],
      pilotTypes: [],
      pilotTypesValues: [],
      nameType: '',
      pilotInstructors: [],
      pilotInstructorsValues: [],
      instructorName: '',
      registration: '',
      duration: 0,
      double: false,
      showInstructors: false,
    };
  },
  async mounted() {
    await this.$store.dispatch('twentyFlights');
    this.twentyFlights = this.$store.getters.twentyFlights;
    this.pilot = this.$store.getters.pilot;
  },
  methods: {
    async editFlight(number, id) {
      this.dialogMode = 'edit';
      await this.$store.dispatch('editFlight', id);
      this.editedFlight = this.$store.getters.editFlight;

      //------------------------//
      // START- en LANDINGSVELD //
      //------------------------//
      await this.$store.dispatch('pilotFields', this.editedFlight.id_pilot);
      this.pilotFields = this.$store.getters.pilotFields;
      this.pilotFieldsValues = this.pilotFields.map(({ name }) => name);
      const fieldStart = this.pilotFields.find(item => item.id === this.editedFlight.id_pilot_field_start);
      this.nameFieldStart = fieldStart.name;
      const fieldLanding = this.pilotFields.find(item => item.id === this.editedFlight.id_pilot_field_landing);
      this.nameFieldLanding = fieldLanding.name;

      //-----------------------//
      // TYPES EN REGISTRATIES //
      //-----------------------//
      // 1. haal gevlogen vliegtuig op: vw_pilot_planes
      //    this.registration = pilotPlane.registration
      await this.$store.dispatch('pilotPlane', this.editedFlight.id_pilot_plane);
      this.pilotPlane = this.$store.getters.pilotPlane;
      this.double = !this.pilotPlane.single;
      //
      // 2. haal de types per piloot op: pilotTypes --> this.editedFlight.id_pilot
      await this.$store.dispatch('pilotTypes', this.editedFlight.id_pilot);
      this.pilotTypes = this.$store.getters.pilotTypes;
      this.pilotTypesValues = this.pilotTypes.map(({ name }) => name);
      //
      // 3. selecteer het type en registratie die bij 1 horen
      //
      this.nameType = this.pilotPlane.name;
      this.registration = this.pilotPlane.registration;
      // 3. Kijk of er nog meer registraties van dat type zijn, toon die dan
      //
      await this.$store.dispatch('typeRegistrations', this.pilotPlane.id_type);
      this.typeRegistrations = this.$store.getters.typeRegistrations;
      this.typeRegistrationsValues = this.typeRegistrations.map(({ registration }) => registration);

      // Instructeur, alleen voor vluchttype 'D'
      if (this.editedFlight.flight_type === 'D') {
        await this.$store.dispatch('pilotInstructors', this.editedFlight.id_pilot);
        this.pilotInstructors = this.$store.getters.pilotInstructors;
        this.pilotInstructorsValues = this.pilotInstructors.map(({ name }) => name);
        const pilotInstructor = this.pilotInstructors.find(item => item.id === this.editedFlight.id_instructor);
        this.instructorName = pilotInstructor.name;
        this.showInstructors = true;
      } else {
        this.showInstructors = false;
      }

      ////
      await this.$store.dispatch('pilotPlanes', this.editedFlight.id_pilot);
      this.pilotPlanes = this.$store.getters.pilotPlanes;
      this.pilotPlanesValues = this.pilotPlanes.map(({ registration }) => registration);
      // const registration = this.pilotPlanes.find(item => item.id_pilot_plane === this.editedFlight.id_pilot_plane);

      // Duur
      this.duration = calcDuration(this.editedFlight.time_start, this.editedFlight.time_landing);

      this.dialog = true;
    },
    async addFlight() {
      this.dialogMode = 'add';

      // haal alle drop down content op en zet defaults
      //------------------------//
      // START- en LANDINGSVELD //
      //------------------------//
      await this.$store.dispatch('pilotFields', this.pilot.id_pilot);
      this.pilotFields = this.$store.getters.pilotFields;
      this.pilotFieldsValues = this.pilotFields.map(({ name }) => name);
      // const fieldStart = this.pilotFields.find(item => item.id === this.editedFlight.id_pilot_field_start);
      this.nameFieldStart = 'Teuge';
      // const fieldLanding = this.pilotFields.find(item => item.id === this.editedFlight.id_pilot_field_landing);
      this.nameFieldLanding = 'Teuge';

      // //-----------------------//
      // // TYPES EN REGISTRATIES //
      // //-----------------------//
      // // 1. haal gevlogen vliegtuig op: vw_pilot_planes
      // //    this.registration = pilotPlane.registration
      // await this.$store.dispatch('pilotPlane', this.editedFlight.id_pilot_plane);
      // this.pilotPlane = this.$store.getters.pilotPlane;
      // this.double = !this.pilotPlane.single;
      // //
      // // 2. haal de types per piloot op: pilotTypes --> this.editedFlight.id_pilot
      await this.$store.dispatch('pilotTypes', this.editedFlight.id_pilot);
      this.pilotTypes = this.$store.getters.pilotTypes;
      this.pilotTypesValues = this.pilotTypes.map(({ name }) => name);
      this.nameType = this.pilotTypesValues[0];
      this.double = !this.pilotTypes[0].single;
      // //
      // // 3. selecteer het type en registratie die bij 1 horen
      // //
      // this.nameType = this.pilotPlane.name;
      // this.registration = this.pilotPlane.registration;
      // // 3. Kijk of er nog meer registraties van dat type zijn, toon die dan
      // //
      await this.$store.dispatch('typeRegistrations', this.pilotTypes[0].id_type);
      this.typeRegistrations = this.$store.getters.typeRegistrations;
      this.typeRegistrationsValues = this.typeRegistrations.map(({ registration }) => registration);
      this.registration = this.typeRegistrationsValues[0];

      // Instructeur
      await this.$store.dispatch('pilotInstructors', this.pilot.id_pilot);
      this.pilotInstructors = this.$store.getters.pilotInstructors;
      this.pilotInstructorsValues = this.pilotInstructors.map(({ name }) => name);
      // const pilotInstructor = this.pilotInstructors.find(item => item.id === this.editedFlight.id_instructor);
      this.instructorName = '';

      // ////
      // await this.$store.dispatch('pilotPlanes', this.editedFlight.id_pilot);
      // this.pilotPlanes = this.$store.getters.pilotPlanes;
      // this.pilotPlanesValues = this.pilotPlanes.map(({ registration }) => registration);
      // // const registration = this.pilotPlanes.find(item => item.id_pilot_plane === this.editedFlight.id_pilot_plane);

      // Maak een nieuwe vlucht met defaults
      this.editedFlight.ID = 0;
      this.editedFlight.cross_country = 0;
      // Datum vandaag
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();
      this.editedFlight.date = `${yyyy}-${mm}-${dd}`;
      this.editedFlight.distance = 0;
      this.editedFlight.dualflight = 0;
      this.editedFlight.duration = '00:00:00';
      this.editedFlight.duration_dual = '00:00:00';
      this.editedFlight.duration_instructor = '00:00:00';
      this.editedFlight.duration_pic = '00:00:00';
      // haal nieuwe vluchtnummer op
      await this.$store.dispatch('getNewFlightNo', this.pilot.id_pilot);
      this.editedFlight.flight_no = this.$store.getters.newFlightNo;
      this.editedFlight.flight_type = 'S';
      this.showInstructors = false;
      this.editedFlight.id_instructor = '';
      this.editedFlight.id_pilot = this.pilot.id_pilot;
      // this.editedFlight.id_pilot_field_landing: (...)
      // this.editedFlight.id_pilot_field_start: (...)
      // this.editedFlight.id_pilot_plane: (...)
      this.editedFlight.instruction = 0;
      this.editedFlight.launch_method = 'L';
      this.editedFlight.no_of_flights = 1;
      this.editedFlight.no_of_tmg = 0;
      this.editedFlight.no_of_tow = 0;
      this.editedFlight.no_of_winch = 1;
      this.editedFlight.olc = '';
      this.editedFlight.outlanding = 0;
      this.editedFlight.pic_pax = 0;
      this.editedFlight.pic_solo = 1;
      this.editedFlight.remark = '';
      this.editedFlight.time_landing = '10:00:00';
      this.editedFlight.time_start = '10:00:00';

      // Duur
      this.duration = calcDuration(this.editedFlight.time_start, this.editedFlight.time_landing);

      this.dialog = true;
    },
    mmsChanged() {
      // minutes start time changed
      this.duration = calcDuration(this.editedFlight.time_start, this.editedFlight.time_landing);
      this.menu2 = false;
    },
    mmlChanged() {
      // minutes landing time changed
      this.duration = calcDuration(this.editedFlight.time_start, this.editedFlight.time_landing);
      this.menu3 = false;
    },
    hhChanged() {
      // hours start or landing time changed
      this.duration = calcDuration(this.editedFlight.time_start, this.editedFlight.time_landing);
    },
    async typeChanged() {
      const typeChosen = this.pilotTypes.find(item => item.name === this.nameType);
      await this.$store.dispatch('typeRegistrations', typeChosen.id_type);
      this.typeRegistrations = this.$store.getters.typeRegistrations;
      this.typeRegistrationsValues = this.typeRegistrations.map(({ registration }) => registration);
      this.registration = this.typeRegistrations[0].registration;
      this.double = !this.typeRegistrations[0].single;
      if (!this.double) {
        this.editedFlight.flight_type = 'S';
      }
    },
    async flightTypeChanged() {
      if (this.editedFlight.flight_type === 'D') {
        this.showInstructors = true;
        await this.$store.dispatch('pilotInstructors', this.editedFlight.id_pilot);
        this.pilotInstructors = this.$store.getters.pilotInstructors;
        this.pilotInstructorsValues = this.pilotInstructors.map(({ name }) => name);
      } else {
        this.showInstructors = false;
      }
      this.instructorName = '';
    },
    async processInput() {
      this.dialog = false;
      //
      // BEGIN this.editedFlight WAARDEN GOED ZETTEN
      // console.clear();
      //
      // id startveld ophalen
      const fieldStart = this.pilotFields.find(item => item.name === this.nameFieldStart);
      this.editedFlight.id_pilot_field_start = fieldStart.id;
      // time start
      if (this.editedFlight.time_start.length === 5) {
        this.editedFlight.time_start += ':00';
      }
      // id landingsveld ophalen
      const fieldLanding = this.pilotFields.find(item => item.name === this.nameFieldLanding);
      this.editedFlight.id_pilot_field_landing = fieldLanding.id;
      // time landing
      if (this.editedFlight.time_landing.length === 5) {
        this.editedFlight.time_landing += ':00';
      }
      // id_pilot_plane
      const pilot_plane = this.typeRegistrations.find(item => item.registration === this.registration);
      this.editedFlight.id_pilot_plane = pilot_plane.id_pilot_plane;
      // Duur
      this.editedFlight.duration = `${this.duration}:00`;
      this.editedFlight.duration_dual = '00:00:00';
      this.editedFlight.duration_pic = '00:00:00';
      this.editedFlight.duration_instructor = '00:00:00';
      switch (this.editedFlight.flight_type) {
        case 'D':
          this.editedFlight.duration_dual = this.editedFlight.duration;
          this.editedFlight.dualflight = 1;
          break;
        case 'S':
          this.editedFlight.duration_pic = this.editedFlight.duration;
          this.editedFlight.pic_solo = 1;
          break;
        case 'P':
          this.editedFlight.duration_pic = this.editedFlight.duration;
          this.editedFlight.pic_pax = 1;
          break;
        case 'I':
          this.editedFlight.duration_instructor = this.editedFlight.duration;
          this.editedFlight.instruction = 1;
          break;
      }
      // id_instructor
      if (this.editedFlight.flight_type === 'D') {
        const instructor = this.pilotInstructors.find(item => item.name === this.instructorName);
        this.editedFlight.id_instructor = instructor.ID;
      } else {
        this.editedFlight.id_instructor = null;
      }
      // Aantallen
      this.editedFlight.no_of_self = 0;
      this.editedFlight.no_of_tmg = 0;
      this.editedFlight.no_of_tow = 0;
      this.editedFlight.no_of_winch = 0;
      switch (this.editedFlight.launch_method) {
        case 'L':
          this.editedFlight.no_of_winch = this.editedFlight.no_of_flights;
          break;
        case 'S':
          this.editedFlight.no_of_tow = this.editedFlight.no_of_flights;
          break;
        case 'T':
          this.editedFlight.no_of_tmg = this.editedFlight.no_of_flights;
          break;
        case 'Z':
          this.editedFlight.no_of_self = this.editedFlight.no_of_flights;
          break;
      }

      // afstand
      if (this.editedFlight.distance === '') {
        this.editedFlight.distance = 0;
      }

      // EINDE this.editedFlight WAARDEN GOED ZETTEN

      if (this.dialogMode === 'add') {
        await this.$store.dispatch('insertFlight', this.editedFlight);
      } else {
        await this.$store.dispatch('updateFlight', this.editedFlight);
      }

      // update store
      await this.$store.dispatch('getTotals', this.pilot.id_pilot);
      await this.$store.dispatch('twentyFlights');
      this.twentyFlights = this.$store.getters.twentyFlights;

      // // HULP: Controleer wijzigingen
      // for (const [key, value] of Object.entries(this.editedFlight)) {
      //   console.log(`${key}: ${value}`);
      // }
    },
    dummy() {},
  },
};
</script>
