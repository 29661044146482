<template>
  <v-container>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      single-line
      hide-details
      background-color="white"
      class="mt-0"
    ></v-text-field>
    <v-data-table
      dense
      :headers="headers"
      :items="allFlights"
      :footer-props="{ 'items-per-page-options': [12] }"
      :items-per-page="12"
      :search="search"
      class="elevation-1 mt-2"
      @click:row="handleClick"
    >
    </v-data-table>
    <!-- Begin details HIER -->
    <template>
      <div class="text-center">
        <v-dialog v-model="showDetails">
          <v-card>
            <v-card-text dense class="white--text pt-1 pb-1 " style="background-color:#0D47A1; font-size:18px">
              Start nummer {{ flight.flight_no }}
            </v-card-text>
            <v-row>
              <v-container>
                <v-col>
                  <v-row>
                    <v-col class="d-inline-flex mt-0 pt-0">
                      <v-icon>mdi-calendar</v-icon>
                      <v-card-text> {{ flight.date }} </v-card-text>
                      <v-icon>mdi-airplane-takeoff</v-icon>
                      <v-card-text> {{ nameFieldStart }} {{ flight.time_start }} </v-card-text>
                      <v-icon>mdi-airplane-landing</v-icon>
                      <v-card-text> {{ nameFieldLanding }} {{ flight.time_landing }} </v-card-text>
                      <v-icon>mdi-timer-outline</v-icon>
                      <v-card-text> {{ flight.duration }} </v-card-text>
                      <v-icon v-if="flight.flight_type === 'I'">mdi-pound</v-icon>
                      <v-card-text v-if="flight.flight_type === 'I'"> {{ flight.no_of_flights }} </v-card-text>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="md-12 mt-0 pt-0">
                  <v-row>
                    <v-col class="d-inline-flex mt-0 pt-0">
                      <v-icon>mdi-airplane</v-icon>
                      <v-card-text> {{ pilotPlane.name }} {{ pilotPlane.registration }}</v-card-text>
                      <v-card-text>Methode: {{ launchMethod }} </v-card-text>
                      <v-card-text>Soort vlucht: {{ flightType }} </v-card-text>
                      <v-icon v-if="flight.flight_type === 'D'">mdi-teach</v-icon>
                      <v-card-text v-if="flight.flight_type === 'D'">{{ instructorName }}</v-card-text>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="md-12 mt-0 pt-0">
                  <v-row>
                    <v-col class="d-inline-flex mt-0 pt-0">
                      <v-icon>mdi-account-voice</v-icon>
                      <v-card-text> {{ flight.remark }} </v-card-text>
                      <v-card-text v-if="flight.cross_country">Overland {{ flight.distance }} km</v-card-text>
                    </v-col>
                  </v-row>
                </v-col>
              </v-container>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" :href="flight.olc" target="_blank" small v-if="isOLC">
                OLC
              </v-btn>
              <v-btn color="success" small v-if="isOLC" @click="showMap">
                Kaart
              </v-btn>
              <v-btn color="#EF6C00" class="white--text" small @click="editFlight">
                Edit
              </v-btn>
              <v-btn color="primary" small @click="showDetails = false">
                Sluiten
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showMapImage" v-if="isOLC" width="unset">
          <!-- <v-card v-if="flight !== {}" height="600px"> -->
          <v-img
            contain
            max-height="600px"
            :src="require(`../../img/OLC/${mapFileName}`)"
            @click="showMapImage = false"
          ></v-img>
          <!-- </v-card> -->
        </v-dialog>
      </div>
    </template>
    <!-- Eind details HIER -->
    <!-- EDIT FLIGHT -->
    <!-- EDIT FLIGHT -->
    <!-- EDIT FLIGHT -->
    <v-dialog v-model="dialog" persistent>
      <v-container>
        <v-card>
          <p dense class="white--text pt-1 pb-1 pl-10" style="background-color:#0D47A1; font-size:18px">
            Edit start {{ flight.flight_no }}
          </p>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col md="12">
                  <v-row>
                    <v-col md="4">
                      <v-menu ref="menu" v-model="menu" :close-on-content-click="true" offset-y min-width="200px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="flight.date"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker dense v-model="flight.date" no-title scrollable> </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col md="4">
                      <v-select
                        :items="pilotTypesValues"
                        v-model="nameType"
                        prepend-icon="mdi-airplane"
                        dense
                        @change="typeChanged"
                      ></v-select>
                    </v-col>
                    <v-col md="4">
                      <v-row class="pt-2">
                        <v-radio-group row dense v-model="flight.flight_type" @change="flightTypeChanged">
                          <v-radio value="S" label="Solo"></v-radio>
                          <v-radio value="P" label="Pax" v-if="this.double"></v-radio>
                          <v-radio value="D" label="DBO" v-if="this.double"></v-radio>
                          <v-radio value="I" label="Instructie" v-if="this.double && this.pilot.instructor"></v-radio>
                        </v-radio-group>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="4">
                      <v-select
                        :items="pilotFieldsValues"
                        v-model="nameFieldStart"
                        prepend-icon="mdi-airplane-takeoff"
                        dense
                      ></v-select>
                    </v-col>
                    <v-col md="4">
                      <v-select
                        :items="typeRegistrationsValues"
                        v-model="registration"
                        prepend-icon="mdi-numeric"
                        dense
                      ></v-select>
                    </v-col>
                    <v-col md="4" class="mt-3">
                      <v-row>
                        <v-select
                          v-if="showInstructors"
                          :items="pilotInstructorsValues"
                          v-model="instructorName"
                          prepend-icon="mdi-teach"
                          dense
                        ></v-select>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="4">
                      <v-menu
                        ref="menu"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="flight.time_start"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu2"
                          v-model="flight.time_start"
                          format="24hr"
                          @click:minute="mmsChanged"
                          @click:hour="hhChanged"
                          width="290"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col md="4">
                      <v-row>
                        <v-radio-group dense v-model="flight.launch_method">
                          <v-row class="mt-6  ml-11">
                            <v-radio value="L" label="Lier" class="mr-2"></v-radio>
                            <v-radio value="S" label="Sleep" class="mr-2"></v-radio>
                            <p></p>
                          </v-row>
                        </v-radio-group>
                      </v-row>
                    </v-col>
                    <v-col md="4">
                      <v-row>
                        <v-checkbox v-model="flight.cross_country" label="Overland" class="ml-2"></v-checkbox>
                        <v-checkbox v-model="flight.outlanding" label="Buiten" class="ml-2"></v-checkbox>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="4">
                      <v-select
                        :items="pilotFieldsValues"
                        v-model="nameFieldLanding"
                        prepend-icon="mdi-airplane-landing"
                        dense
                      ></v-select>
                    </v-col>
                    <v-col md="4">
                      <v-text-field dense readonly prepend-icon="mdi-timer-outline" v-model="duration"></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        dense
                        prepend-icon="mdi-map-marker-distance"
                        v-model="flight.distance"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="4">
                      <v-menu
                        ref="menu"
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="flight.time_landing"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu3"
                          v-model="flight.time_landing"
                          format="24hr"
                          @click:minute="mmlChanged"
                          @click:hour="hhChanged"
                          width="290"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        v-model="flight.no_of_flights"
                        prepend-icon="mdi-pound"
                        v-if="this.pilot.instructor"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4"></v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-text-field dense prepend-icon="mdi-account-voice" v-model="flight.remark"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <v-text-field
                    dense
                    prepend-icon="mdi-map-outline"
                    v-model="flight.olc"
                    v-if="flight.cross_country"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <!-- KNOPPEN -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="warning" @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn small color="primary" @click="processInput">
              Opslaan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>
    <!-- /EDIT FLIGHT -->
    <!-- /EDIT FLIGHT -->
    <!-- /EDIT FLIGHT -->
  </v-container>
</template>

<script>
import { calcDuration } from '../../util/standard';
export default {
  data() {
    return {
      search: '',
      headers: [
        { text: 'Vluchtno', class: 'blue--text text--darken-4', value: 'flight_no' },
        { text: 'Datum', class: 'blue--text text--darken-4', value: 'date' },
        { text: 'Veld', class: 'blue--text text--darken-4', value: 'name_field_start' },
        { text: 'Start', class: 'blue--text text--darken-4', value: 'time_start' },
        { text: 'Type', class: 'blue--text text--darken-4', value: 'name_type' },
        { text: 'Registratie', class: 'blue--text text--darken-4', value: 'registration' },
        // { text: 'Methode', class: 'blue--text text--darken-4', value: 'launch_method' },
        { text: 'Duur', class: 'blue--text text--darken-4', value: 'duration' },
        { text: 'Opmerking', class: 'blue--text text--darken-4', value: 'remark' },
      ],
      allFlights: [],
      showDetails: false,
      flight: {},
      pilotFields: [],
      pilotFieldsValues: [],
      nameFieldStart: '',
      nameFieldLanding: '',
      pilotPlane: {},
      launchMethod: '',
      flightType: '',
      pilotInstructors: [],
      pilotInstructorsValues: [],
      instructorName: '',
      showMapImage: false,
      isOLC: false,
      // EDIT
      dialog: false,
      menu: false,
      menu2: false,
      menu3: false,
      double: false,
      nameType: '',
      pilotTypes: [],
      pilotTypesValues: [],
      typeRegistrations: [],
      typeRegistrationsValues: [],
      registration: '',
      showInstructors: false,
      duration: 0,
      mapFileName: '',
    };
  },
  async created() {
    this.pilot = this.$store.getters.pilot;
    await this.$store.dispatch('allFlights');
    this.allFlights = this.$store.getters.allFlights;
  },
  methods: {
    async handleClick(row) {
      this.showDetails = true;
      await this.$store.dispatch('editFlight', row.ID);
      this.flight = this.$store.getters.editFlight;
      // console.clear();
      // for (const [key, value] of Object.entries(this.flight)) {
      //   console.log(`${key}: ${value}`);
      // }

      // START- en LANDINGSVELD //
      await this.$store.dispatch('pilotFields', this.flight.id_pilot);
      this.pilotFields = this.$store.getters.pilotFields;
      this.pilotFieldsValues = this.pilotFields.map(({ name }) => name);
      const fieldStart = this.pilotFields.find(item => item.id === this.flight.id_pilot_field_start);
      this.nameFieldStart = fieldStart.name;
      if (this.flight.id_pilot_field_landing !== 0) {
        const fieldLanding = this.pilotFields.find(item => item.id === this.flight.id_pilot_field_landing);
        this.nameFieldLanding = fieldLanding.name;
      } else {
        this.nameFieldLanding = 'Buitenlanding';
      }

      // Vliegtuig //
      await this.$store.dispatch('pilotPlane', this.flight.id_pilot_plane);
      this.pilotPlane = this.$store.getters.pilotPlane;

      // Methode //
      switch (this.flight.flight_type) {
        case 'D':
          this.flightType = 'DBO';
          break;
        case 'S':
          this.flightType = 'Solo';
          break;
        case 'P':
          this.flightType = 'Passagier';
          break;
        case 'I':
          this.flightType = 'Instructie';
          break;
      }

      // Vluchttype //
      switch (this.flight.launch_method) {
        case 'L':
          this.launchMethod = 'Lier';
          break;
        case 'S':
          this.launchMethod = 'Sleep';
          break;
        case 'T':
          this.launchMethod = 'TMG';
          break;
        case 'Z':
          this.launchMethod = 'Zelfstart';
          break;
      }

      // Instructeur, alleen voor vluchttype 'D'
      if (this.flight.flight_type === 'D') {
        await this.$store.dispatch('pilotInstructors', this.flight.id_pilot);
        this.pilotInstructors = this.$store.getters.pilotInstructors;
        this.pilotInstructorsValues = this.pilotInstructors.map(({ name }) => name);
        const pilotInstructor = this.pilotInstructors.find(item => item.ID === this.flight.id_instructor);
        this.instructorName = pilotInstructor.name;
      }

      if (this.flight.olc === null || this.flight.olc === '') {
        this.mapFileName = 'noMap.jpg';
        this.isOLC = false;
      } else {
        this.mapFileName = `${this.flight.date}-OLC.jpg`;
        this.isOLC = true;
      }

      // for (const [key, value] of Object.entries(row)) {
      //   console.log(`${key}: ${value}`);
      // }
    },
    showMap() {
      this.showMapImage = true;
    },
    async editFlight() {
      //------------------------//
      // START- en LANDINGSVELD //
      //------------------------//
      await this.$store.dispatch('pilotFields', this.flight.id_pilot);
      this.pilotFields = this.$store.getters.pilotFields;
      this.pilotFieldsValues = this.pilotFields.map(({ name }) => name);
      const fieldStart = this.pilotFields.find(item => item.id === this.flight.id_pilot_field_start);
      this.nameFieldStart = fieldStart.name;
      const fieldLanding = this.pilotFields.find(item => item.id === this.flight.id_pilot_field_landing);
      this.nameFieldLanding = fieldLanding.name;
      //-----------------------//
      // TYPES EN REGISTRATIES //
      //-----------------------//
      // 1. haal gevlogen vliegtuig op: vw_pilot_planes
      //    this.registration = pilotPlane.registration
      await this.$store.dispatch('pilotPlane', this.flight.id_pilot_plane);
      this.pilotPlane = this.$store.getters.pilotPlane;
      this.double = !this.pilotPlane.single;
      //
      // 2. haal de types per piloot op: pilotTypes --> this.flight.id_pilot
      await this.$store.dispatch('pilotTypes', this.flight.id_pilot);
      this.pilotTypes = this.$store.getters.pilotTypes;
      this.pilotTypesValues = this.pilotTypes.map(({ name }) => name);
      //
      // 3. selecteer het type en registratie die bij 1 horen
      //
      this.nameType = this.pilotPlane.name;
      this.registration = this.pilotPlane.registration;
      // 3. Kijk of er nog meer registraties van dat type zijn, toon die dan
      //
      await this.$store.dispatch('typeRegistrations', this.pilotPlane.id_type);
      this.typeRegistrations = this.$store.getters.typeRegistrations;
      this.typeRegistrationsValues = this.typeRegistrations.map(({ registration }) => registration);
      // Instructeur, alleen voor vluchttype 'D'
      if (this.flight.flight_type === 'D') {
        await this.$store.dispatch('pilotInstructors', this.flight.id_pilot);
        this.pilotInstructors = this.$store.getters.pilotInstructors;
        this.pilotInstructorsValues = this.pilotInstructors.map(({ name }) => name);
        const pilotInstructor = this.pilotInstructors.find(item => item.id === this.flight.id_instructor);
        this.instructorName = pilotInstructor.name;
        this.showInstructors = true;
      } else {
        this.showInstructors = false;
      }
      ////
      await this.$store.dispatch('pilotPlanes', this.flight.id_pilot);
      this.pilotPlanes = this.$store.getters.pilotPlanes;
      this.pilotPlanesValues = this.pilotPlanes.map(({ registration }) => registration);
      // const registration = this.pilotPlanes.find(item => item.id_pilot_plane === this.flight.id_pilot_plane);
      // Duur
      this.duration = calcDuration(this.flight.time_start, this.flight.time_landing);
      this.dialog = true;

      if (this.flight.olc === null || this.flight.olc === '') {
        this.mapFileName = 'noMap.jpg';
        this.isOLC = false;
      } else {
        this.mapFileName = `${this.flight.date}-OLC.jpg`;
        this.isOLC = true;
      }
    },
    async processInput() {
      this.dialog = false;
      //
      // BEGIN this.flight WAARDEN GOED ZETTEN
      // console.clear();
      //
      // id startveld ophalen
      const fieldStart = this.pilotFields.find(item => item.name === this.nameFieldStart);
      this.flight.id_pilot_field_start = fieldStart.id;
      // time start
      if (this.flight.time_start.length === 5) {
        this.flight.time_start += ':00';
      }
      // id landingsveld ophalen
      const fieldLanding = this.pilotFields.find(item => item.name === this.nameFieldLanding);
      this.flight.id_pilot_field_landing = fieldLanding.id;
      // time landing
      if (this.flight.time_landing.length === 5) {
        this.flight.time_landing += ':00';
      }
      // id_pilot_plane
      const pilot_plane = this.typeRegistrations.find(item => item.registration === this.registration);
      this.flight.id_pilot_plane = pilot_plane.id_pilot_plane;
      // Duur
      this.flight.duration = `${this.duration}:00`;
      this.flight.duration_dual = '00:00:00';
      this.flight.duration_pic = '00:00:00';
      this.flight.duration_instructor = '00:00:00';
      switch (this.flight.flight_type) {
        case 'D':
          this.flight.duration_dual = this.flight.duration;
          break;
        case 'S':
        case 'P':
          this.flight.duration_pic = this.flight.duration;
          break;
        case 'I':
          this.flight.duration_instructor = this.flight.duration;
          break;
      }
      // id_instructor
      if (this.flight.flight_type === 'D') {
        const instructor = this.pilotInstructors.find(item => item.name === this.instructorName);
        this.flight.id_instructor = instructor.ID;
      }
      // Aantallen
      this.flight.no_of_self = 0;
      this.flight.no_of_tmg = 0;
      this.flight.no_of_tow = 0;
      this.flight.no_of_winch = 0;
      switch (this.flight.launch_method) {
        case 'L':
          this.flight.no_of_winch = this.flight.no_of_flights;
          break;
        case 'S':
          this.flight.no_of_tow = this.flight.no_of_flights;
          break;
        case 'T':
          this.flight.no_of_tmg = this.flight.no_of_flights;
          break;
        case 'Z':
          this.flight.no_of_self = this.flight.no_of_flights;
          break;
      }

      // afstand
      if (this.flight.distance === '') {
        this.flight.distance = 0;
      }

      // EINDE this.flight WAARDEN GOED ZETTEN

      await this.$store.dispatch('updateFlight', this.flight);

      // update store
      await this.$store.dispatch('getTotals', this.pilot.id_pilot);
      await this.$store.dispatch('twentyFlights');
      this.twentyFlights = this.$store.getters.twentyFlights;
      await this.$store.dispatch('allFlights');
      this.allFlights = this.$store.getters.allFlights;

      // // // HULP: Controleer wijzigingen
      // // for (const [key, value] of Object.entries(this.flight)) {
      // //   console.log(`${key}: ${value}`);
      // // }
      this.showDetails = false;
    },
    async flightTypeChanged() {
      if (this.flight.flight_type === 'D') {
        this.showInstructors = true;
        await this.$store.dispatch('pilotInstructors', this.flight.id_pilot);
        this.pilotInstructors = this.$store.getters.pilotInstructors;
        this.pilotInstructorsValues = this.pilotInstructors.map(({ name }) => name);
      } else {
        this.showInstructors = false;
      }
      this.instructorName = '';
    },

    async typeChanged() {
      const typeChosen = this.pilotTypes.find(item => item.name === this.nameType);
      await this.$store.dispatch('typeRegistrations', typeChosen.id_type);
      this.typeRegistrations = this.$store.getters.typeRegistrations;
      this.typeRegistrationsValues = this.typeRegistrations.map(({ registration }) => registration);
      this.registration = this.typeRegistrations[0].registration;
      this.double = !this.typeRegistrations[0].single;
      if (!this.double) {
        this.flight.flight_type = 'S';
      }
    },
    mmsChanged() {
      // minutes start time changed
      this.duration = calcDuration(this.flight.time_start, this.flight.time_landing);
      this.menu2 = false;
    },
    mmlChanged() {
      // minutes landing time changed
      this.duration = calcDuration(this.flight.time_start, this.flight.time_landing);
      this.menu3 = false;
    },
    hhChanged() {
      // hours start or landing time changed
      this.duration = calcDuration(this.flight.time_start, this.flight.time_landing);
    },
  },
};
</script>

<style scoped>
.v-text-field {
  width: 400px;
}
</style>
