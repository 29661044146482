<template>
  <div>
    <v-container class="d-flex flex-col mb-6">
      <v-data-table
        dense
        :headers="headers"
        :items="flights"
        :footer-props="{ 'items-per-page-options': [10, 15, 20, 25, 30] }"
        :items-per-page="15"
        class="elevation-1 tabel"
      >
        <template v-slot:item.distance="{ value }"> {{ value }} km </template>
        <template #item.olc="{ value }">
          <div v-if="value !== null">
            <a target="_blank" :href="value">
              OLC
            </a>
          </div>
        </template>
        <template #item.mapName="{ value }">
          <div v-if="value !== undefined">
            <v-btn color="primary" outlined x-small @click="showMap(value)">
              Kaart
            </v-btn>
          </div>
        </template>
      </v-data-table>
      <v-dialog v-model="showMapImage" v-if="showMapImage" width="unset">
        <!-- <v-card v-if="flight !== {}" height="600px"> -->
        <v-img
          contain
          max-height="600px"
          :src="require(`../../img/OLC/${mapFileName}`)"
          @click="showMapImage = false"
        ></v-img>
        <!-- </v-card> -->
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      flights: [],
      headers: [
        { text: 'Vluchtno', class: 'blue--text text--darken-4', value: 'flight_no' },
        { text: 'Datum', align: 'left', sortable: false, class: 'blue--text text--darken-4', value: 'date' },
        { text: 'Type', align: 'left', sortable: false, class: 'blue--text text--darken-4', value: 'name_type' },
        { text: 'Afstand', align: 'left', sortable: false, class: 'blue--text text--darken-4', value: 'distance' },
        { text: 'Omschrijving', align: 'left', sortable: false, class: 'blue--text text--darken-4', value: 'remark' },
        { text: '', align: 'left', sortable: false, class: 'blue--text text--darken-4', value: 'olc' },
        { text: '', align: 'left', sortable: false, class: 'blue--text text--darken-4', value: 'mapName' },
      ],
      showMapImage: false,
      mapFileName: '',
    };
  },
  methods: {
    showMap(mapName) {
      this.showMapImage = true;
      this.mapFileName = mapName;
    },
  },
  async mounted() {
    await this.$store.dispatch('getCrossCountry');
    this.flights = this.$store.getters.crossCountry;
  },
};
</script>
