<template>
  <v-container>
    <v-row>
      <v-col md="2">
        <v-menu offset-y :close-on-click="closeOnClick">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" class="white--text text--darken-4">
              <v-icon class="white--text text--darken-4 mr-3">mdi-file-document-multiple-outline</v-icon>
              Overzichten
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in items" :key="index" @click="reportChosen(item.title)">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col md="4">
        <h3 class="blue--text text--darken-4 ml-16">{{ nameReport }}</h3>
      </v-col>
      <v-col md="1"> </v-col>
    </v-row>
    <v-row>
      <v-col>
        <component :is="selectedReport"></component>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AllFlights from './AllFlights';
import TotalsYear from './TotalsYear';
import TotalsYearInstruction from './TotalsYearInstruction';
import TotalsType from './TotalsType';
import TotalsTypeYear from './TotalsTypeYear';
import TotalsField from './TotalsField';
import TotalsFieldYear from './TotalsFieldYear';
import TotalsInstructor from './TotalsInstructor';
import TotalsMethod from './TotalsMethod';
import TotalsPlane from './TotalsPlane';
import CrossCountry from './CrossCountry';

export default {
  components: {
    AllFlights,
    TotalsYear,
    TotalsYearInstruction,
    TotalsType,
    TotalsTypeYear,
    TotalsField,
    TotalsFieldYear,
    TotalsInstructor,
    TotalsMethod,
    TotalsPlane,
    CrossCountry,
  },
  data() {
    return {
      nameReport: '',
      items: [
        { title: 'Alle starts' },
        { title: 'Totalen per jaar' },
        { title: 'Totalen per type' },
        { title: 'Totalen per type per jaar' },
        { title: 'Totalen per veld' },
        { title: 'Totalen per veld per jaar' },
        { title: 'Totalen per instructeur' },
        { title: 'Totalen per methode' },
        { title: 'Totalen per registratie' },
        { title: 'Overland' },
        { title: 'Instructie per jaar' },
      ],
      closeOnClick: true,
      selectedReport: '',
    };
  },
  methods: {
    reportChosen(nameReport) {
      switch (nameReport) {
        case 'Alle starts':
          this.nameReport = 'Alle starts';
          this.selectedReport = 'AllFlights';
          break;
        case 'Totalen per jaar':
          this.nameReport = 'Totalen per jaar';
          this.selectedReport = 'TotalsYear';
          break;
        case 'Totalen per type':
          this.nameReport = 'Totalen per type';
          this.selectedReport = 'TotalsType';
          break;
        case 'Totalen per type per jaar':
          this.nameReport = 'Totalen per type per jaar';
          this.selectedReport = 'TotalsTypeYear';
          break;
        case 'Totalen per veld':
          this.nameReport = 'Totalen per veld';
          this.selectedReport = 'TotalsField';
          break;
        case 'Totalen per veld per jaar':
          this.nameReport = 'Totalen per veld per jaar';
          this.selectedReport = 'TotalsFieldYear';
          break;
        case 'Totalen per instructeur':
          this.nameReport = 'Totalen per instructeur';
          this.selectedReport = 'TotalsInstructor';
          break;
        case 'Totalen per methode':
          this.nameReport = 'Totalen per methode';
          this.selectedReport = 'TotalsMethod';
          break;
        case 'Totalen per registratie':
          this.nameReport = 'Totalen per registratie';
          this.selectedReport = 'TotalsPlane';
          break;
        case 'Overland':
          this.nameReport = 'Overland';
          this.selectedReport = 'CrossCountry';
          break;
        case 'Instructie per jaar':
          this.nameReport = 'Instructie per jaar';
          this.selectedReport = 'TotalsYearInstruction';
          break;
      }
    },
  },
};
</script>
