import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/components/Home';
import Blank from '@/components/Blank';
// import store from '../store';

/////////////PILOT//////////////
import Profile from '@/components/Pilot/Profile';
import SignIn from '@/components/Pilot/SignIn';
import SignOut from '@/components/Pilot/SignOut';
import PageTotals from '@/components/Report/PageTotals';
/////////////REPORT/////////////
import Wrapper from '@/components/Report/Wrapper';
/////////////VLUCHT/////////////

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'blank',
    component: Blank,
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
  },
  {
    path: '/report/wrapper',
    name: 'wrapper',
    component: Wrapper,
  },
  {
    path: '/pilot/profile',
    name: 'Account',
    component: Profile,
  },
  {
    path: '/report/pagetotals',
    name: 'pagetotals',
    component: PageTotals,
  },
  {
    path: '/pilot/signout',
    name: 'SignOut',
    component: SignOut,
  },
  {
    path: '/pilot/signin',
    name: 'signin',
    component: SignIn,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
