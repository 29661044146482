<template>
  <v-container>
    <v-layout>
      <v-card class="mx-auto mt-16" width="400">
        <v-card-title class="primary white--text  mb-4 justify-center">LOGIN</v-card-title>
        <v-card-text>
          <v-container>
            <form @submit.prevent="submit">
              <v-layout row>
                <v-flex xs12>
                  <v-text-field
                    prepend-icon="mdi-account"
                    id="email"
                    v-model="email"
                    type="email"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs12>
                  <v-text-field
                    prepend-icon="mdi-lock"
                    id="password"
                    v-model="password"
                    type="password"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs12>
                  <v-btn block type="submit" color="primary" class="white--text  mt-3">
                    Log in
                  </v-btn>
                </v-flex>
              </v-layout>
            </form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-layout>
    <v-layout row v-if="error">
      <v-flex xs12 sm3 mx-auto offset-sm3>
        <v-alert color="error" class="mt-6">{{ error }}</v-alert>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      error: '',
    };
  },
  computed: {},
  watch: {},
  methods: {
    async submit() {
      this.error = '';
      const message = await this.$store.dispatch('signIn', { email: this.email, password: this.password });
      if (message === 'ok') {
        this.$router.replace({
          name: 'home',
        });
      } else {
        this.error = 'Login gegevens niet correct...';
      }
    },
  },
};
</script>
