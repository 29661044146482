import axios from 'axios';

import { secondsToHours } from '../util/standard';

export default {
  ///////AUTH///////
  async signIn({ commit }, credentials) {
    const response = await axios.post('pilot/login', credentials);
    if (response.data.success === 1) {
      commit('SET_TOKEN', response.data.token);
      commit('SET_PILOT', response.data.pilot);
      commit('SET_AUTH', true);

      // totaal start en uren piloot
      const result = await axios.get(`/report/totalspilot/${response.data.pilot.id_pilot}`, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      });
      result.data[0].uren = secondsToHours(result.data[0].uren);
      commit('SET_TOTALS', result.data[0]);

      return 'ok';
    } else {
      return 'fout';
    }
  },
  signOut({ commit }) {
    commit('SET_TOKEN', null);
    commit('SET_PILOT', null);
    commit('SET_AUTH', false);
  },
  ///////REPORT//////
  async allFlights({ commit }) {
    const response = await axios.get(`/report/allflights/${this.state.pilot.id_pilot}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    commit('SET_ALLFLIGHTS', response.data);
  },
  async totalsYear({ commit }) {
    const response = await axios.get(`/report/totalsyear/${this.state.pilot.id_pilot}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    response.data.forEach(row => {
      row.duur = secondsToHours(row.duur);
    });
    commit('SET_TOTALSYEAR', response.data);
  },
  async totalsYearInstruction({ commit }) {
    const response = await axios.get(`/report/totalsyearinstruction/${this.state.pilot.id_pilot}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    response.data.forEach(row => {
      row.duur = secondsToHours(row.duur);
    });
    commit('SET_TOTALSYEARINSTRUCTION', response.data);
  },
  async twentyFlights({ commit }) {
    const response = await axios.get(`/report/twentyflights/${this.state.pilot.id_pilot}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    commit('SET_TWENTYFLIGHTS', response.data);
  },
  async totalsType({ commit }) {
    const response = await axios.get(`/report/totalstype/${this.state.pilot.id_pilot}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    response.data.forEach(row => {
      row.duur = secondsToHours(row.duur);
    });
    commit('SET_TOTALSTYPE', response.data);
  },
  async totalsTypeYear({ commit }) {
    const response = await axios.get(`/report/totalstypeyear/${this.state.pilot.id_pilot}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    let yearOld = 0;
    response.data.forEach(row => {
      row.duur = secondsToHours(row.duur);
      if (row.jaar === yearOld) {
        row.jaar = null;
      } else {
        yearOld = row.jaar;
      }
    });
    commit('SET_TOTALSTYPEYEAR', response.data);
  },
  async totalsField({ commit }) {
    const response = await axios.get(`/report/totalsfield/${this.state.pilot.id_pilot}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    response.data.forEach(row => {
      row.duur = secondsToHours(row.duur);
    });
    commit('SET_TOTALSFIELD', response.data);
  },
  async totalsFieldYear({ commit }) {
    const response = await axios.get(`/report/totalsfieldyear/${this.state.pilot.id_pilot}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    let yearOld = 0;
    response.data.forEach(row => {
      row.duur = secondsToHours(row.duur);
      if (row.jaar === yearOld) {
        row.jaar = null;
      } else {
        yearOld = row.jaar;
      }
    });
    commit('SET_TOTALSFIELDYEAR', response.data);
  },
  async totalsInstructor({ commit }) {
    const response = await axios.get(`/report/totalsinstructor/${this.state.pilot.id_pilot}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    response.data.forEach(row => {
      row.duur = secondsToHours(row.duur);
    });
    commit('SET_TOTALSINSTRUCTOR', response.data);
  },
  async totalsMethod({ commit }) {
    const response = await axios.get(`/report/totalsmethod/${this.state.pilot.id_pilot}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    response.data.forEach(row => {
      row.duur = secondsToHours(row.duur);
    });
    commit('SET_TOTALSMETHOD', response.data);
  },
  async pageTotals({ commit }, flightno) {
    const response = await axios.get(`/pilot/pagetotals/${this.state.pilot.id_pilot}/${flightno}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    response.data.flight_time = secondsToHours(response.data.flight_time);
    response.data.PIC = secondsToHours(response.data.PIC);
    response.data.DBO = secondsToHours(response.data.DBO);
    response.data.instructor = secondsToHours(response.data.instructor);
    commit('SET_PAGETOTALS', response.data);
  },
  async totalsPlane({ commit }) {
    const response = await axios.get(`/report/totalsplane/${this.state.pilot.id_pilot}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    response.data.forEach(row => {
      row.duur = secondsToHours(row.duur);
    });
    commit('SET_TOTALSPLANE', response.data);
  },
  //////MAINT////////
  async editFlight({ commit }, id) {
    const response = await axios.get(`/maint/flightbyid/${id}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    response.data[0].date = response.data[0].date.substring(0, 10);
    commit('SET_EDITFLIGHT', response.data[0]);
  },
  async pilotFields({ commit }) {
    const response = await axios.get(`/maint/pilotfields/${this.state.pilot.id_pilot}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    commit('SET_PILOTFIELDS', response.data);
  },
  async pilotPlanes({ commit }) {
    const response = await axios.get(`/maint/pilotplanes/${this.state.pilot.id_pilot}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    commit('SET_PILOTPLANES', response.data);
  },
  async pilotTypes({ commit }) {
    const response = await axios.get(`/maint/pilottypes/${this.state.pilot.id_pilot}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    commit('SET_PILOTTYPES', response.data);
  },
  async pilotInstructors({ commit }) {
    const response = await axios.get(`/maint/pilotinstructors/${this.state.pilot.id_pilot}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    commit('SET_PILOTINSTRUCTORS', response.data);
  },
  async typeRegistrations({ commit }, id_type) {
    const response = await axios.get(`/maint/typeregistrations/${this.state.pilot.id_pilot}/${id_type}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    commit('SET_TYPEREGISTRATIONS', response.data);
  },
  async pilotPlane({ commit }, id) {
    const response = await axios.get(`/maint/pilotplanebyid/${id}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    commit('SET_PILOTPLANE', response.data[0]);
  },
  async updateFlight(_, payload) {
    const id = payload.ID;
    await axios.put(`/maint/updateflight/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
  },
  async insertFlight(_, payload) {
    await axios.post(`/maint/insertflight/1`, payload, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
  },
  async getNewFlightNo({ commit }, id) {
    const response = await axios.get(`/maint/newflightno/${id}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    commit('SET_NEWFLIGHTNO', response.data[0].new_flight_no);
  },
  async getTotals({ commit }, id) {
    // totaal start en uren piloot
    const result = await axios.get(`/report/totalspilot/${id}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    result.data[0].uren = secondsToHours(result.data[0].uren);
    commit('SET_TOTALS', result.data[0]);
  },
  async getCrossCountry({ commit }) {
    // overlandvluchten
    const result = await axios.get(`/report/crosscountry/${this.state.pilot.id_pilot}`, {
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    result.data.forEach(flight => {
      if (flight.olc !== null) {
        flight.mapName = `${flight.date}-OLC.jpg`;
      }
    });
    commit('SET_CROSSCOUNTRY', result.data);
  },
};
